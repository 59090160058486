import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import axios from "axios";
import {createBrowserRouter, Router, RouterProvider} from "react-router-dom";
import Mehndi from "./pages/Mehndi";
import Welcome from "./pages/Welcome";
import NotFound from "./pages/NotFound";
import MuntakimLima from "./pages/MuntakimLima";

// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyAzu4gjOYK1CHGNpsFkUfluO2eltXF40BU",
    authDomain: "moments-capture-203bc.firebaseapp.com",
    projectId: "moments-capture-203bc",
    storageBucket: "moments-capture-203bc.appspot.com",
    messagingSenderId: "248030767261",
    appId: "1:248030767261:web:2ec781a4ac4d3505450c74"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/muntakim-lima",
        element: <MuntakimLima />
    },
    {
      path: "/wedding",
      element: <App />
    },
    {
        path: "/",
        element: <Welcome />
    },
    {
        path: "*",
        element: <NotFound />,
    }
]);

root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
