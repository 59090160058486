import * as React from 'react';
import {ref, uploadBytes} from "firebase/storage";
import {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';


type props = {
    isOpen: boolean;
    setIsOpen: any;
    uploadFiles: any;
    folderName: string
    storage: any;
    setUploadFile: any;
    setErrorModalDetails: any;
    setCompletedModal: any;
}

const ConfirmModal = ({ isOpen, setIsOpen, uploadFiles, folderName, storage, setUploadFile, setErrorModalDetails, setCompletedModal }: props) => {
    const [selectedOption, setSelectedOption] = useState("");
    const [uploadStatus, setUploadStatus] = useState("");
    const [uploadCounter, setUploadCounter] = useState(0);

    useEffect(() => {
        if (uploadFiles?.length === uploadCounter) {
            setUploadStatus("completed")
        }
    }, [uploadCounter])

    const handleClose = () => {
        setIsOpen(false);
        setUploadFile(null);
    };



    const uploadFileToFirebase = (uploadFile: any) => {
        setUploadStatus("pending")
        let refUrl = "";

        if (!!selectedOption && selectedOption === "grooms-family") {
            refUrl = "wedding/grooms-family"
        } else if (!!selectedOption && selectedOption === "brides-family") {
            refUrl = "wedding/brides-family"
        } else {
            refUrl = `${folderName}`
        }

        const albumRef = ref(storage, refUrl);

        const uuid = uuidv4();
        const fileRef = ref(albumRef, uuid)
        if (uploadFile && uploadFile.size < 524288000.22) { // if upload file size is < 500MB (mebibytes) // 524288000.22
            uploadBytes(fileRef, uploadFile).then((snapshot) => {
                setUploadCounter((prevState) => (prevState + 1));
            }).catch((error) => {
                console.error(error);
            })

        } else {
            console.error("File is too large, exceeding 500MB")
            setErrorModalDetails("File is too large, exceeding 500MB")
            setUploadStatus("")
            setIsOpen(false)
        }
    }

    const handleUpload = () => {
        for (const uploadFile of uploadFiles) {
            if(uploadFile.type.startsWith("image") || uploadFile.type.startsWith("video")) {
                uploadFileToFirebase(uploadFile)
            } else {
                console.error("Unsupported file type")
                setErrorModalDetails(`Unsupported file type for file: ${uploadFile?.name}`)
            }
        }
    }

    useEffect(() => {
        if (uploadStatus === "completed") {
            handleClose();
            setCompletedModal(true);
        }
    }, [uploadStatus])

    return (
        <div id="defaultModal" onClick={() => setIsOpen(false)}>
            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-h-full m-6 lg:max-w-[80%] max-w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-center px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <span className="font-semibold">Upload {uploadFiles?.length > 1 ? `${uploadFiles.length} items` : uploadFiles[0].name}?</span>
                            </div>
                            {uploadFiles?.length === 1 && (
                                <div className="flex justify-center">
                                    {uploadFiles[0].type.startsWith('image/') ? (
                                        <img className="w-full max-h-[70vh]" src={URL.createObjectURL(uploadFiles[0])} alt="Selected" />
                                    ) : uploadFiles[0].type.startsWith('video/') ? (
                                        <video controls width="100%">
                                            <source src={URL.createObjectURL(uploadFiles[0])} type={uploadFiles[0].type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : null}
                                </div>
                            )}
                            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    className="mt-3 w-full sm:w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                                    onClick={handleUpload}
                                    disabled={uploadStatus === "pending"}
                                >
                                    {uploadStatus === "pending" ? "Uploading..." : "Proceed"}
                                </button>
                                <button
                                    className="mt-3 w-full sm:w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ConfirmModal;