import React, {useEffect, useState} from 'react';
import './assets/App.css';
import {getStorage} from "firebase/storage";
import Upload from "./components/Upload";
import Gallery from "./components/Gallery";
import ConfirmModal from "./components/ConfirmModal";
import MediaModel from "./components/MediaModel";
import {mediaSrcType} from "../types/media-src";
import bgImage from "./assets/images/bg-image.jpg";
import { useLocation } from "react-router-dom";
import ErrorModal from "./components/ErrorModal";
import CompletedModal from "./components/CompletedModal";

type fileType = {
    url: string;
    fileType: string;
}

function App() {

    const weddingToken = "f8d7e6c5-b4a3-2109-8f7e-6d5c4b3a2f10";

    const [folderName, setFolderName] = useState("wedding")
    const [confirmModal, setConfirmModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalDetails, setErrorModalDetails] = useState(null);
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [mediaModel, setMediaModel] = useState(false);
    const [completedModal, setCompletedModal] = useState(false);
    const [mediaModelSrc, setMediaModelSrc] = useState({ src: "", type: "video" || "image", path: "" });
    const [accessToken, setAccessToken] = useState("");
    const [folderPaths, setFolderPaths] = useState<string[] | null>(null);

    const storage = getStorage();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const getAccessToken = queryParams.get("access_token")

    useEffect(() => {
        if (!!getAccessToken && getAccessToken === weddingToken) {
            setFolderPaths(['/wedding']);
        }
    }, [getAccessToken])

    useEffect(() => {
        if (uploadFile) {
            setConfirmModal(true);
        }
    }, [uploadFile])

    useEffect(() => {
        if (getAccessToken)
            setAccessToken(getAccessToken)
    }, [getAccessToken])

    const handleModal = (src: mediaSrcType) => {
        setMediaModel(true);
        setMediaModelSrc(src);
    }

    useEffect(() => {
        if (errorModalDetails) {
            setErrorModal(true);
        }
    }, [errorModalDetails])

    useEffect(() => {
        if (!errorModal) {
            setErrorModalDetails(null);
        }
    }, [errorModal])

    return (
        <div className="App w-full container mx-auto h-max">
            {/* Title */}
            <h1 className="text-lg font-bold text-white pb-4">MOMENTS CAPTURE</h1>
            <div>
                <div className={`lg:h-[70vh] h-[90vh] bg-center bg-no-repeat bg-cover w-full opacity-30 rounded`} style={{ backgroundImage: `url(${bgImage})` }}></div>
                <h2 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-56 md:-translate-y-56 -translate-y-60 text-white p-4 lg:text-6xl text-4xl font-semibold">Jahangir & Moobina's Wedding</h2>
                <h4 className="sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-16 md:-translate-y-8 -translate-y-4 text-white md:text-lg text-base">Upload any images or videos of the wedding</h4>
                {/* Upload function */}
                <Upload uploadFile={uploadFile} setUploadFile={setUploadFile} />
            </div>

            {/* Gallery */}
            <Gallery
                storage={storage}
                handleModal={handleModal}
                folderPaths={folderPaths}
            />

            <ConfirmModal
                folderName={folderName}
                storage={storage}
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                uploadFiles={uploadFile}
                setUploadFile={setUploadFile}
                setErrorModalDetails={setErrorModalDetails}
                setCompletedModal={setCompletedModal}
            />

            <ErrorModal
                isOpen={errorModal}
                setIsOpen={setErrorModal}
                errorModalDetails={errorModalDetails}
            />

            <MediaModel
                isOpen={mediaModel}
                setIsOpen={setMediaModel}
                media={mediaModelSrc}
                storage={storage}
            />

            <CompletedModal
                isOpen={completedModal}
                setIsOpen={setCompletedModal}
            />
        </div>
    );
}

export default App;
