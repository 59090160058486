import * as React from "react";
import {useRef, useState} from "react";
import playIcon from "../assets/icons/play.svg";

type props = {
    videoUrl: string;
    handleModal: any;
    path: string;
    storage: any;
}

const VideoThumbnail = ({ videoUrl, handleModal, path }: props) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [imageDataUrl, setImageDataUrl] = useState('');

    return (
        <div className="flex flex-col items-center justify-center lg:h-80 overflow-hidden lg:w-auto lg:w-cover rounded bg-black cursor-pointer w-40 h-40"
             onClick={() => handleModal({ src: videoUrl, type: "video", path: path })}
        >
            <img className="absolute h-20" src={playIcon} />
            <div className="bg-black opacity-30">
                <video ref={videoRef} src={videoUrl} className="lg:w-auto w-40" />
                <canvas ref={canvasRef} style={{ display: 'none' }} />
                {imageDataUrl && <img src={imageDataUrl} alt="Captured Frame" />}
            </div>
        </div>
    );
}

export default VideoThumbnail;