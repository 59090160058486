import * as React from "react";
import bgImage from "../assets/images/welcome-bg.jpg";
import Upload from "../components/Upload";

function Welcome () {
    return (
        <div className="App w-full container mx-auto h-max">
            {/* Title */}
            <h1 className="text-lg font-bold text-white pb-4">MOMENTS CAPTURE</h1>
            <div>
                <div className={`lg:h-[70vh] h-[90vh] bg-center bg-no-repeat bg-cover w-full opacity-30 rounded`} style={{ backgroundImage: `url(${bgImage})` }}></div>
                <h2 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-56 md:-translate-y-56 -translate-y-60 text-white p-4 lg:text-6xl text-4xl font-semibold">Welcome to Moments Capture</h2>
                <h4 className="sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-16 md:-translate-y-8 -translate-y-4 text-white md:text-lg text-base">A place to upload pictures and videos for those special moments.</h4>
            </div>
        </div>
    );
}

export default Welcome;