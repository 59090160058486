import * as React from "react";
import {useEffect, useState} from "react";
import {getStorage} from "firebase/storage";
import {useLocation} from "react-router-dom";
import {mediaSrcType} from "../../types/media-src";
import bgImage from "../assets/images/bg-ring.jpg";
import Upload from "../components/Upload";
import Gallery from "../components/Gallery";
import ConfirmModal from "../components/ConfirmModal";
import ErrorModal from "../components/ErrorModal";
import MediaModel from "../components/MediaModel";
import CompletedModal from "../components/CompletedModal";

function MuntakimLima() {
    const weddingToken = "b7a3e4a1-b4a3-2109-8f7e-6d5c4b3a2f10";

    const [folderName, setFolderName] = useState("muntakim-lima")
    const [confirmModal, setConfirmModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalDetails, setErrorModalDetails] = useState(null);
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [mediaModel, setMediaModel] = useState(false);
    const [completedModal, setCompletedModal] = useState(false);
    const [mediaModelSrc, setMediaModelSrc] = useState({ src: "", type: "video" || "image", path: "" });
    const [accessToken, setAccessToken] = useState("");
    const [folderPaths, setFolderPaths] = useState<string[] | null>(null);

    const storage = getStorage();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const getAccessToken = queryParams.get("access_token")

    useEffect(() => {
        if (!!getAccessToken && getAccessToken === weddingToken) {
            setFolderPaths(['/muntakim-lima']);
        }
    }, [getAccessToken])

    useEffect(() => {
        if (uploadFile) {
            setConfirmModal(true);
        }
    }, [uploadFile])

    useEffect(() => {
        if (getAccessToken)
            setAccessToken(getAccessToken)
    }, [getAccessToken])

    const handleModal = (src: mediaSrcType) => {
        setMediaModel(true);
        setMediaModelSrc(src);
    }

    useEffect(() => {
        if (errorModalDetails) {
            setErrorModal(true);
        }
    }, [errorModalDetails])

    useEffect(() => {
        if (!errorModal) {
            setErrorModalDetails(null);
        }
    }, [errorModal])

    return (
        <div className="App w-full container mx-auto h-max">
            {/* Title */}
            <h1 className="text-lg font-bold text-white pb-4">MOMENTS CAPTURE</h1>
            <div>
                <div className={`lg:h-[70vh] h-[90vh] bg-center bg-no-repeat bg-cover w-full opacity-30 rounded`} style={{ backgroundImage: `url(${bgImage})` }}></div>
                <h2 className="absolute text-[#fffaf0] top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-56 md:-translate-y-56 -translate-y-60 text-white p-4 lg:text-6xl text-4xl font-semibold">Muntakim <br/> & <br/> Lima's <br/> Wedding</h2>

                <div className="sm:block absolute top-2/3 lg:top-1/2 left-1/2 transform -translate-x-1/2 lg:translate-y-20 md:translate-y-12 -translate-y-4">
                    <h4 className="text-white md:text-lg text-base">Upload any images or videos of the wedding</h4>

                    <div className="relative lg:translate-y-8 -translate-y-12">
                        {/* Upload function */}
                        <Upload uploadFile={uploadFile} setUploadFile={setUploadFile} />
                    </div>
                </div>
            </div>

            {/* Gallery */}
            <Gallery
                storage={storage}
                handleModal={handleModal}
                folderPaths={folderPaths}
            />

            <ConfirmModal
                folderName={folderName}
                storage={storage}
                isOpen={confirmModal}
                setIsOpen={setConfirmModal}
                uploadFiles={uploadFile}
                setUploadFile={setUploadFile}
                setErrorModalDetails={setErrorModalDetails}
                setCompletedModal={setCompletedModal}
            />

            <ErrorModal
                isOpen={errorModal}
                setIsOpen={setErrorModal}
                errorModalDetails={errorModalDetails}
            />

            <MediaModel
                isOpen={mediaModel}
                setIsOpen={setMediaModel}
                media={mediaModelSrc}
                storage={storage}
            />

            <CompletedModal
                isOpen={completedModal}
                setIsOpen={setCompletedModal}
            />
        </div>
    );
}

export default MuntakimLima;