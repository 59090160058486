import * as React from 'react';
import {useEffect, useRef} from "react";

type props = {
    setUploadFile: any;
    uploadFile: any;
}

const Upload = ({ uploadFile, setUploadFile }: props) => {

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!uploadFile) {
            resetFileInput();
        }
    }, [uploadFile])

    const resetFileInput = () => {
        setUploadFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFile = (event: any) => {
        setUploadFile(event.target.files)
    }


    return (
        <div className="pt-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 translate-y-16">
            <label htmlFor="file-upload"
                   className="inline-block py-2 px-3.5 cursor-pointer bg-blue-500 text-white rounded w-52 font-semibold">
                Upload
            </label>
            <input
                id="file-upload"
                type="file"
                accept="image/*, video/*"
                name="file"
                onChange={handleFile}
                ref={fileInputRef}
                multiple
            />
        </div>
    );
}

export default Upload;