import * as React from 'react';
import {mediaSrcType} from "../../types/media-src";
import crossIcon from "../assets/icons/cross.svg";
import {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";

type props = {
    isOpen: boolean;
    setIsOpen: any;
    media: mediaSrcType;
    storage: any;
}

const MediaModel = ({ isOpen, setIsOpen, media, storage }: props) => {

    const [src, setSrc] = useState("");

    useEffect(() => {
        if (!media.src && !!media.path) {
            const fetchDownloadUrl = async () => {
                try {
                    const fileRef = ref(storage, "gs://moments-capture-203bc.appspot.com/" + media.path);
                    const url = await getDownloadURL(fileRef);
                    setSrc(url);
                } catch (error) {
                    console.error("Error fetching files", error);
                }
            }
            fetchDownloadUrl();
        } else {
            setSrc(media.src)
        }
    }, [media])

    return (
        <div id="defaultModal" onClick={() => setIsOpen(false)}>
            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto px-4 pb-16 max-h-[100vh] overflow-y-hidden">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                        <button>
                            <img src={crossIcon} className="h-16 absolute top-0 right-0 cursor-pointer" />
                        </button>
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-h-full m-6 lg:max-w-[80%] max-w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                            onClick={(e) => e.stopPropagation()}
                        >

                            {media.type === "video" ?
                                <video src={src} className="max-h-[100vh]" controls /> :
                                <img src={src} className="w-full max-h-[90vh]" />
                            }

                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MediaModel;