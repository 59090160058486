import * as React from "react";

function NotFound() {
    return (
        <div className="App w-full container mx-auto h-max mt-20">
            <h1 className="font-sans text-4xl text-white flex justify-center items-center text-center">Error 404 - Page Not Found</h1>
        </div>
    );
}

export default NotFound;