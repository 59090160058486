import * as React from 'react';
import {Fragment, useEffect, useState} from "react";
import {getDownloadURL, getMetadata, listAll, ref} from "firebase/storage";
import VideoThumbnail from "./VideoThumbnail";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

type fileType = {
    url: string;
    fileType: string;
    fullPath: string;
}

type props = {
    storage: any;
    handleModal: any;
    folderPaths: string[] | null;
}

const PAGE_SIZE = 8; // Number of items per page

const Gallery = ({storage, handleModal, folderPaths}: props) => {

    const [downloadFiles, setDownloadFiles] = useState<fileType[]>([]);
    const [lastDocument, setLastDocument] = useState(null);
    const [loadMore, setLoadMore] = useState(true);
    const [currentPos, setCurrentPos] = useState(PAGE_SIZE)

    useEffect(() => {
        if (!!folderPaths && loadMore) {
            fetchFiles(folderPaths);
            setCurrentPos((currentPos) => currentPos + PAGE_SIZE);
            setLoadMore(false);
        }
    }, [folderPaths, loadMore])

    const fetchFiles = async (folderPaths: any) => {
        try {

            const allUrls = [];

            for (const folderPath of folderPaths) {
                let url = "gs://moments-capture-203bc.appspot.com" + folderPath;
                const folderRef = ref(storage, url);
                const mediaItem = await listAll(folderRef);
                const fileItems = mediaItem.items.slice((currentPos-PAGE_SIZE), currentPos);

                const downloadUrls = await Promise.all(
                    fileItems.map(async (file) => {
                        const metadata = await getMetadata(file);
                        const fileSizeInMB = metadata.size / (1024 * 1024);

                        let type = "";
                        if (metadata.contentType?.startsWith("image")) {
                            type = "image";
                        } else if (metadata.contentType?.startsWith("video")) {
                            type = "video";
                        } else {
                            console.error("Retrieved unsupported file");
                        }

                        if (fileSizeInMB < 30) {
                            const downloadURL = await getDownloadURL(file);
                            return { url: downloadURL, fileType: type, fullPath: metadata.fullPath };
                        } else {
                            return { url: null, fileType: type, fullPath: metadata.fullPath };
                        }
                    })
                )

                const filteredDownloadUrls = downloadUrls.filter((url) => url !== undefined) as fileType[];
                setDownloadFiles((current) => [...current, ...filteredDownloadUrls]);
            }
        } catch (error) {
            console.error("Error fetching files", error);
        }
    }

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        if (scrollTop + clientHeight >= scrollHeight - 20) {
            setLoadMore(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", throttledHandleScroll);
        return () => window.removeEventListener("scroll", throttledHandleScroll);
    }, []);

    const throttle = (func: any, delay: any) => {
        let lastCall = 0;
        return (...args: any) => {
            const now = new Date().getTime();
            if (now - lastCall < delay) return;
            lastCall = now;
            func(...args);
        };
    };

    const throttledHandleScroll = throttle(handleScroll, 200); // Adjust the delay as needed


    return (
        <div className="pt-4 grid lg:grid-cols-4 grid-cols-2 gap-4 justify-center">
            {downloadFiles.map((file, index) => {
                if (file.fileType === "image") {
                    return <img
                        className="lg:h-80 lg:w-96 object-cover rounded cursor-pointer w-40 h-40"
                        key={index}
                        src={file.url}
                        onClick={() => handleModal({ src: file.url, type: "image" })}
                    />
                } else if (file.fileType === "video") {
                    return (
                        <Fragment key={index}>
                            {downloadFiles && <VideoThumbnail videoUrl={file.url} path={file.fullPath} handleModal={handleModal} storage={storage} />}
                        </Fragment>
                    )
                } else {
                    return <div>Unsupported file type</div>
                }
            })}
        </div>
    );
}

export default Gallery;