import * as React from "react";

import okIcon from "../assets/icons/ok.svg"

const CompletedModal = ({ isOpen, setIsOpen }: any) => {

    const handleClose = () => {
        setIsOpen(false)
        window.location.reload();
    }

    return (
        <div id="defaultModal" onClick={() => setIsOpen(false)}>
            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-h-full m-6 lg:max-w-[30%] max-w-full w-1/2 p-4"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-center px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <span className="font-semibold text-green-900">Completed</span>
                            </div>
                            <div className="flex justify-center">
                                <img src={okIcon} className="h-24" />
                            </div>
                            <div className="flex justify-center w-full py-4 text-center">
                                The media item has been uploaded successfully.
                            </div>
                            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    className="mt-3 w-full sm:w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CompletedModal;
